@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.board-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 4px;
  margin-bottom: 5px;
  min-width: fit-content;
  user-select: none;
}

@media(max-height: 500px) {
  .board-container {
    margin-bottom: 1px;
  }
}

.heat-bar {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
}

.heat-bar.drain {
  flex-direction: column-reverse;
  font-weight: bold;
}

.heat-bar div {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
  align-items: center;
  width: 5em;
}

.heat-bar .mid {
  flex: 1;
  align-items: flex-end;
}

.heat-bar .ends {
  height: 1.5em;
}

.heat-bar.disabled .color {
  opacity: 50%;
}

.heat-bar .color {
  display: block;
  width: 18px;
  height: 100%;
  background-color: lightgray;
  flex-grow: 0;
  flex-shrink: 0;
}

.heat-bar .unavailable .color:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  --color: #444;
  --l: 2px; /* line-width */
  background: linear-gradient(to top right, transparent calc(50% - var(--l) / 2), var(--color) calc(50% - var(--l) / 2) calc(50% + var(--l) / 2), transparent calc(50% + var(--l) / 2)),
              linear-gradient(to bottom right, transparent calc(50% - var(--l) / 2), var(--color) calc(50% - var(--l) / 2) calc(50% + var(--l) / 2), transparent calc(50% + var(--l) / 2));
}

.heat-bar .safe .color {
  background-color: #ffd702;
}

.heat-bar .mid > .color {
  position: relative;
  background-color: lightgray;
}

.heat-bar .mid > .color > .color {
  position: absolute;
  background-image: linear-gradient(180deg, #f82929, #09b722);
}

.heat-bar.drain .mid > .color > .color {
  background-image: linear-gradient(0deg, #f82929, #7b68ee);
}

.heat-bar .dangerous .color {
  background-color: #f82960;
}

.heat-bar .indicator {
  position: relative;
  text-align: right;
  padding-right: 2px;
}

.heat-bar .mid .indicator {
  position: relative;
  right: 0;
  transform: translate(2px, +50%);
}

.heat-bar.disabled .indicator {
  display: none;
}

.heat-bar .here {
  font-weight: bold;
  padding-right: 7px;
}

.heat-bar .here::after {
  content: '▸';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(2px, -58.7%);
}

table.board {
  table-layout: fixed;
  margin: auto;
  border-spacing: 2px;
}

table.board * {
  margin: 0;
  padding: 0;
}

table.board tr {
  line-height: 20px;
}

td.blk {
  width: 18px;
  min-width: 18px;
  height: 18px;
  overflow: visible;
  border-spacing: 0;
  font-family: monospace;
  font-size: 20px;
  border: 3px solid #b0b0b0;
  background-color: #d2d2d2;
  position: relative;
}

@media (max-width: 400px) or (max-height: 400px) {
  table.board {
    border-spacing: 1px;
  }

  td.blk {
    border-width: 1.5px;
  }
}

td.blk span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  cursor: default;
  user-select: none;
}

td.blk span.bp5-icon {
  transform: translate(-50%, -50%);
  opacity: 50%;
}

td.blk-open {
  border-color: #eeeeee;
  background-color: #f3f3f3;
  font-weight: bold;
}

span.num-1 {
  color: #0301f0;
}

span.num-2 {
  color: #098703;
}

span.num-3 {
  color: #e30200;
}

span.num-4 {
  color: #00006e;
}

span.num-5 {
  color: #650101;
}

span.num-6 {
  color: #0c7f74;
}

span.num-7 {
  color: #000000;
}

span.num-8 {
  color: #787878;
}

td.blk-dead {
  background-color: red;
  border-color: black;
  color: black;
}

span.flag {
  color: #cb4444;
  font-size: 15px;
  transform: translate(-50%, -45%);
}

.blk-safe {
  border-color: #ffd702 !important;
}

.blk-dangerous {
  border-color: #f82960 !important;
}

td.blk-best {
  border-color: gold;
  background-color: gold;
  background-image: linear-gradient(135deg, #ffda16, #e7c100) !important;
}

td.blk-preferred {
  border-color: #fbff90;
  background-color: #fbff90;
  background-image: linear-gradient(135deg, #ffef89, #cbff7d) !important;
}

span.bad-flag {
  font-weight: bold;
  text-decoration: line-through;
}

td.blk-won {
  border-color: #07a907;
  background-color: #09b722;
  background-image: linear-gradient(135deg, #3bec4b, #22a414);
}

td.blk-won span.flag {
  color: white;
}

td.blk-drain {
  border-color: mediumslateblue;
  border-style: dashed;
}

.game {
  width: fit-content;
  margin: 20px;
  margin-top: 0;
}

.centering {
  margin: auto;
}

.control .short-btns .bp5-button {
  min-width: initial;
}

.control .bp5-form-group.bp5-inline {
  justify-content: space-between;
}

.control .bp5-control-group.bp5-vertical {
  align-items: stretch;
}

.control .bp5-control-group.bp5-vertical .bp5-button-group {
  width: 100%;
}

.control .bp5-button-group .bp5-button.growing {
  flex-grow: 1;
}

.control .bp5-form-content:has(> .bp5-slider) {
  padding: 0 20px;
}

.control pre {
  margin: auto;
}

.control h4 {
  margin-bottom: 10px;
}

.control p {
  word-break: break-word;
  word-wrap: break-word;
  max-width: 190px;
  margin: auto;
}

.app.control p {
  max-width: 250px;
  hyphens: auto;
  margin: unset;
}

main:has(.game) {
  display: block !important;
  padding: 0;
}

#playground-root:has(.compact.control) {
  padding-bottom: 120px;
}

*:has(.compact.control) {
  min-width: fit-content;
}

.compact.control {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 9px;
  position: fixed;
  margin: 0 auto;
  bottom: 15px;
  left: 0;
  right: 0;
}

.compact.control .bp5-control-group {
  justify-content: space-between;
}

@media(max-height: 450px) {
  .compact.control {
    flex-direction: row;
    gap: 2px;
    bottom: 8px;
  }

  .bp5-form-group {
    margin-bottom: 5px;
  }

  #playground-root:has(.compact.control) {
    padding-bottom: 65px;
  }
}

.control.bp5-compact {
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.bp5-form-group {
  margin-bottom: 8px;
}

.bp5-form-group:last-child {
  margin-bottom: 0;
}

input[type=file] {
  width: 0;
  min-width: initial;
}

@media (min-width: 350px) and (max-width: 650px),
       (min-width: 350px) and (max-height: 480px) {
  .app {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-column-gap: 15px;
    max-width: 100%;
    width: fit-content;
  }

  .app h3 {
    grid-column: 1 / span 2;
  }

  .app h4 {
    margin-top: 8px;
  }

  .app > * {
    grid-column: 1;
  }

  .app > br {
    display: none;
  }

  .app .description {
    grid-column: 2;
    grid-row: 2 / span 9;
  }

  .app .description h4 {
    margin-top: 0;
  }
}
